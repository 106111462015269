<template>
  <div>

    <!-- button -->
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="flat-success"
      class="btn-icon"
      @click="$refs.menu.open"
      @contextmenu.prevent="$refs.menu.open"
    >
      <feather-icon
        size="40"
        icon="MoreVerticalIcon"
      />
    </b-button>
    <!-- context -->
    <vue-context ref="menu">
      <b-list-group>
        <b-list-group-item>Cras justo odio</b-list-group-item>
        <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
        <b-list-group-item>Morbi leo risus</b-list-group-item>
        <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
        <b-list-group-item>Vestibulum at eros</b-list-group-item>
      </b-list-group>
    </vue-context>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Vue from 'vue'
import VueContext from 'vue-context'
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueContext,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      menuData: [
        { icon: 'PlusIcon', text: 'New' },
        { icon: 'FileIcon', text: 'Open' },
        { icon: 'SaveIcon', text: 'Save' },
        { icon: 'SaveIcon', text: 'Save As' },
        { icon: 'XIcon', text: 'Close' },
      ],
    }
  },
  methods: {
    optionClicked(text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `You have click on ${text}!`,
          icon,
        },
      })
    },
  },
}
</script>
